// @TODO Build component

import React from 'react';

export default class PrimaryView extends React.Component {

	/**
	 *  Constructor for PrimaryView component class.
	 *
	 *  @param {object} props The props that get passed
	 */
	constructor(props)
	{
		super(props);

		this.state = {};
	}

	render()
	{
		return (

			<div>
			</div>
		);
	}
}